const formattingDate = (date) => {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (month < 10) month = "0" + month;
  if (day < 10) day = "0" + day;
  return year + "-" + month + "-" + day;
};

const filterDateRanges = ({ datesRanges, data, fieldDate }) => {
  let startDate;
  if (datesRanges[0]) {
    startDate = new Date(formattingDate(datesRanges[0]));
  }
  let endDate;
  if (datesRanges[1]) {
    endDate = new Date(formattingDate(datesRanges[1]));
  }

  if (data.length > 0) {
    return data.filter((item) => {
      if (item[fieldDate]) {
        let date = item[fieldDate].split("/");
        let year = date[2];
        let month = date[1];
        let day = date[0];

        date = year + "-" + month + "-" + day;
        date = new Date(date);

        return date >= startDate && date <= endDate;
      }
      return false;
    });
  }
  return [];
};

export default filterDateRanges;
