<template>
  <div>
    <approval-rewards-component v-if="currentTable === 'rewards-approval'" />
    <approved-rewards-component v-if="currentTable === 'rewards-approved'" />
  </div>
</template>

<script>
import useRewards from "@/modules/admin/composables/Analitycs/Marketing/useRewards";
import ApprovalRewardsComponent from "@/modules/admin/components/Analytics/Marketing/Rewards/ApprovalRewardsComponent";
import ApprovedRewardsComponent from "@/modules/admin/components/Analytics/Marketing/Rewards/ApprovedRewardsComponent";

export default {
  components: {
    ApprovalRewardsComponent,
    ApprovedRewardsComponent,
  },
  setup() {
    const { currentTable } = useRewards();
    return { currentTable };
  },
};
</script>