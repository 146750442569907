<template>
  <banner-component :name="scheduledTeacherInfo.name"
    :goToPage="() => goToPage({ id: scheduledTeacherInfo.id, name: 'admin-member-teacher-edit-profile' })"
    :lastName="scheduledTeacherInfo.lastName" :isLoading="isLoadingInfo" />

  <scheduled-teacher-component :scheduledTeacherInfo="scheduledTeacherInfo" :isLoading="isLoadingInfo"/>
</template>

<script>
import BannerComponent from "@/modules/admin/components/Members/Teachers/Scheduled/BannerComponent";
import useScheduledTeacher from "../../../composables/Members/Teachers/useScheduledTeacher";
import scheduledTeacherComponent from "../../../components/Members/Teachers/Scheduled/ScheduledTeacherComponent";

export default {
  components: {
    BannerComponent,
    scheduledTeacherComponent
  },
  setup() {
    const { scheduledTeacherInfo, goToPage , isLoadingInfo , loadServices } = useScheduledTeacher()
    loadServices()
    return {
      scheduledTeacherInfo,
      goToPage,
      isLoadingInfo
    };
  },
};
</script>
