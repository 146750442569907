<template>
    <skeleton v-if="isLoading" borderRadius="10px" height="5rem" class="skeleton-dark mb-3" />
    <div v-else class="bg-primary-5 border-r-10 py-4 px-5 d-flex gap-4 align-items-center text-primary-1 fw-bold ">
        <i @click="goToPage" class="pi pi-arrow-left fs-5 pointer"></i>
        <h3 class="m-0">{{ name }} {{ lastName }}</h3>
    </div>
</template>


<script>
export default {
    props: {
        name: {
            type: String,
            required: true
        },
        lastName: {
            type: String,
            required: true
        },
        goToPage: {
            type: Function,
            required: true
        },
        isLoading: {
            type: Boolean,
            required: true
        }
    },

}
</script>