<template>
    <skeleton v-if="isLoading" borderRadius="10px" height="5rem" class="skeleton-dark mb-3" />
    <div v-else class="d-flex justify-content-between my-4">
        <div class="d-flex gap-3">
            <button style="" :disabled="isLoadingSave" type="button" @click="toggleCreateJobscheduledModal" class="
           btn text-secondary-1 d-flex gap-2 align-items-center border-secondary-1 w-auto bg-hover-light-secondary-1 p-2 px-4
          ">
                Create
            </button>
            <button class="
              btn
              text-primary-1 text-hover-primary-1
              bg-hover-primary-6
              border-primary-1
            " @click="toggleModalLastChanges" :disabled="activeCopyYear || selectTimezone !=='US/Eastern'">
                Copy to 1 year
            </button>

            <div class="d-flex gap-4 align-items-center">
                <h4 class="fw-bold">Time Zone: </h4>
                <Dropdown :options="staticTimezones" :loading="isLoading" @change="changeTimezone"
                    v-model="selectTimezone" optionLabel="label" placeholder="select Timezone" optionValue="value">
                </Dropdown>
            </div>
        </div>
        <div class="d-flex gap-2">
            <button :disabled="isLoadingSave" style="" type="button" @click="toggleClearDateModal" class="
           btn text-secondary-1 d-flex gap-2 align-items-center border-secondary-1 w-auto bg-hover-light-secondary-1 p-2 px-4
          ">
                Clear
            </button>
            <button style="" :disabled="isLoadingSave || selectTimezone !=='US/Eastern'" type="button" @click="saveSchedule"
                class="btn text-white bg-secondary-1 bg-hover-secondary-1 py-2 px-4 bl-shadow w-auto">
                Save
            </button>
        </div>
    </div>
    <skeleton v-if="isLoading" borderRadius="10px" height="50vh" class="skeleton-dark" />
    <template v-else>
        <VueCal v-if="!isLoadingSchedule" ref="calendarScheduled" :time-step="30" :time-cell-height="30" :snap-to-time="30"
            :on-event-create="createEvent" @view-change="getEvents" @event-delete="deleteEventData"
            :on-event-click="(e) => toggleListTeachers(e)" :maxDate="new Date(store.getters['shared/currentTimeTimezone']).addDays(7)" :dblclickToNavigate="false"
            :events="events" style="height: 100%" :drag-to-create-event="true"
            :editable-events="{ title: false, drag: true, resize: true, delete: true, create: true }" />
        <div v-else class="d-flex justify-content-center flex-column align-items-center" style="height:50vh" fill="#264e9c" >
            <ProgressSpinner />
            <h2>Updating schedule</h2>
        </div>
    </template>
</template>


<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import "./ScheduledJob.scss";
import useScheduledTeacher from "../../../../composables/Members/Teachers/useScheduledTeacher";
import { onMounted, onUpdated } from "vue";
import { useStore } from 'vuex'
export default {
    props: {
        scheduledTeacherInfo: {
            type: Object,
            required: true
        },
        isLoading: {
            type: Boolean,
            required: true
        }
    },
    components: {
        VueCal
    },
    setup() {
        const { selectTimezone, staticTimezones, events, clearEvents, toggleClearDateModal, toggleCreateJobscheduledModal, saveSchedule, createEvent, calendarScheduled, toggleModalLastChanges, changeTimezone, isLoadingSave, getEvents, activeCopyYear, deleteEventData, weekDates, toggleListTeachers, isLoadingSchedule } = useScheduledTeacher()

        const store = useStore()

        return {
            selectTimezone,
            isLoadingSchedule,
            deleteEventData,
            toggleListTeachers,
            weekDates,
            activeCopyYear,
            getEvents,
            changeTimezone,
            isLoadingSave,
            toggleModalLastChanges,
            calendarScheduled,
            createEvent,
            saveSchedule,
            toggleCreateJobscheduledModal,
            toggleClearDateModal,
            clearEvents,
            staticTimezones,
            events,
            store
        }
    }

}
</script>