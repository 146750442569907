import { computed, ref } from "vue";
import { useStore } from "vuex";
import { FilterMatchMode } from "primevue/api";
import filterDateRanges from "@/modules/admin/helpers/filterDateRanges";

const useRewardsApproval = () => {
  const store = useStore();
  const dateRanges = ref([]);

  const listApproval = computed(
    () => store.getters["admins/getMarketing"].rewards.approval
  );

  const approvalInfo = computed(() => {
    const datesRanges_ = dateRanges.value ?? [];
    const hasDateRanges = dateRanges.value.length > 0;

    const currentApproval = { ...listApproval.value };
    const data = listApproval.value.data;

    if (hasDateRanges)
      currentApproval.data = filterDateRanges({
        datesRanges: datesRanges_,
        data,
        fieldDate: "claimend",
      });

    return currentApproval;
  });

  const clearDateRanges = () => {
    dateRanges.value = [];
  };

  const approvalFilters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    last_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    claimend: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    discount: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    reward: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    user_payment_status: {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH,
    },
  });

  const isSearching = ref(false);
  const toggleSearching = () => {
    isSearching.value = !isSearching.value;
  };

  const getRewardsApproval = () =>
    store.dispatch("admins/marketingRewardsApproval");

  return {
    approvalInfo,
    getRewardsApproval,
    approvalFilters,
    isSearching,
    toggleSearching,
    dateRanges,
    clearDateRanges,
  };
};

export default useRewardsApproval;
