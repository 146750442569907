<template>
  <charts-register-component />
  <last-registers-component />
</template>

<script>
import useRegisters from "@/modules/admin/composables/Analitycs/Marketing/useRegisters";
import ChartsRegisterComponent from "@/modules/admin/components/Analytics/Marketing/Registers/ChartsRegisterComponent";
import LastRegistersComponent from "@/modules/admin/components/Analytics/Marketing/Registers/LastRegistersComponent";
import { onBeforeMount } from "vue";
export default {
  components: {
    ChartsRegisterComponent,
    LastRegistersComponent,
  },
  setup() {
    const { getRegisters } = useRegisters();
    onBeforeMount(() => {
      getRegisters();
    });
  },
};
</script>