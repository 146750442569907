<template>
  <DataTable
    :value="approvalInfo.data"
    responsiveLayout="scroll"
    :loading="approvalInfo.isLoading"
    :paginator="true"
    ref="tableRewardsApproval"
    :rowsPerPageOptions="[3, 10, 15, 20]"
    :globalFilterFields="[
      'claimend',
      'discount',
      'email',
      'last_name',
      'name',
      'reward',
      'user_payment_status',
    ]"
    :rows="3"
    v-model:selection="selectEmails"
    v-model:filters="approvalFilters"
    @rowSelect="onSelectRow"
    @rowUnselect="onSelectRow"
    @rowSelectAll="onSelectAll"
    @rowUnSelectAll="unSelectAll"
  >
    <template #header>
      <div class="d-flex align-items-center justify-content-between mb-3">
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center mt-2">
            <button
              type="button"
              class="
                btn
                text-hover-primary-1
                p-2
                ps-0
                fs-5
                d-flex
                gap-2
                align-items-center
              "
              :class="[
                currentTable === 'rewards-approval'
                  ? 'text-primary-0'
                  : 'text-primary-1',
              ]"
              @click="changeCurrentTable('rewards-approval')"
            >
              Rewards for approval
              <div class="line-table bg-primary-1"></div>
            </button>
            <button
              type="button"
              class="
                btn
                text-hover-primary-1
                p-2
                ps-0
                fs-5
                d-flex
                gap-2
                align-items-center
              "
              :class="[
                currentTable === 'rewards-approved'
                  ? 'text-primary-0'
                  : 'text-primary-1',
              ]"
              @click="changeCurrentTable('rewards-approved')"
            >
              Rewards approved
            </button>
          </div>
          <div class="d-flex align-items-center gap-1">
            <input-text
              placeholder="Search by name, email ..."
              class="rounded-0"
              v-model="approvalFilters['global'].value"
              v-if="isSearching"
            />
            <i
              class="
                pi pi-search
                py-1
                px-3
                bg-primary-1
                text-white
                fs-5
                icon-search
                pointer
                border-r-10
                overflow-hidden
              "
              @click="toggleSearching"
            ></i>
          </div>
        </div>
        <div class="field-outline-blue">
          <Calendar
            id="icon"
            class="bg-white calendar-small"
            dateFormat="M dd"
            selectionMode="range"
            :manualInput="false"
            :showIcon="true"
            placeholder="MM/DD - MM/DD"
            v-model="dateRanges"
            :showButtonBar="true"
            @clear-click="clearDateRanges"
          />
        </div>
      </div>
    </template>
    <template #empty> No data found. </template>
    <template #loading> Loading data. Please wait. </template>
    <column selectionMode="multiple" headerStyle="width: 3rem"></column>
    <column
      v-for="(header, index) in approvalInfo.header"
      :key="index"
      :field="header.field"
      :header="header.name"
      :sortable="header.sortable"
      style="min-width: 14rem"
    >
      <template #body="{ data }">
        <div
          class="px-3 py-1 w-content rounded-pill first-letter-uppercase"
          v-if="
            header.field === 'user_payment_status' || header.field === 'reward'
          "
          :class="validateText(data[header.field])"
        >
          {{ data[header.field] }}
        </div>
        <p v-else-if="header.field === 'name'">{{data.name}} {{data.last_name ?? ''}}</p>
        <p v-else>{{ data[header.field] }}</p>
      </template>
    </column>
    <column header="Options" style="min-width: 14rem">
      <template #body="{ data }">
        <button
          v-if="data.reward === 'video'"
          class="
            btn
            text-primary-1 text-hover-primary-1
            bg-hover-primary-6
            border-primary-1
            px-5
          "
          @click="() => toggleApproveRewardsModal(true, data)"
        >
          Review
        </button>
      </template>
    </column>

    <template #paginatorstart>
      <div class="d-flex align-items-center justify-content-start gap-3">
        <!-- <Button
          class="p-button-rounded bg-primary-1 text-white border-0 m-0"
          icon="pi pi-check"
          :disabled="selectEmails.length === 0"
          @click="() => multipleApproval()"
          v-tooltip.top="'Approve rewards'"
          style="width: 2.4rem"
        /> -->
        <Button
          class="p-button-rounded bg-primary-1 text-white border-0 m-0"
          icon="pi pi-sort-alt"
          :disabled="loadingExport"
          @click="() => exportCsv('approval')"
          v-tooltip.top="'Export report'"
          style="width: 2.4rem"
        />
      </div>
    </template>
  </DataTable>
  <export-excel
    class="btn btn-default d-none approval-rewards"
    :data="dataExcel"
    :fields="fieldsExcelRewardsApproval"
    worksheet="My Worksheet"
    name="filename.xls"
  >
  </export-excel>
</template>

<script>
import useRewardsApproval from "@/modules/admin/composables/Analitycs/Marketing/useRewardsApproval";
import useRewards from "@/modules/admin/composables/Analitycs/Marketing/useRewards";
import { onBeforeMount } from "vue";
export default {
  setup() {
    const {
      currentTable,
      changeCurrentTable,
      validateText,
      dataExcel,
      fieldsExcelRewardsApproval,
      exportCsv,
      tableRewardsApproval,
      selectEmails,
      loadingExport,
      toggleApproveRewardsModal,
      onSelectAll,
      unSelectAll,
      onSelectRow,
      multipleApproval,
    } = useRewards();
    const {
      approvalInfo,
      getRewardsApproval,
      toggleSearching,
      isSearching,
      approvalFilters,
      dateRanges,
      clearDateRanges,
    } = useRewardsApproval();
    onBeforeMount(() => {
      getRewardsApproval();
    });
    return {
      approvalInfo,
      onSelectAll,
      unSelectAll,
      onSelectRow,
      selectEmails,
      multipleApproval,
      loadingExport,
      currentTable,
      changeCurrentTable,
      tableRewardsApproval,
      validateText,
      dataExcel,
      fieldsExcelRewardsApproval,
      exportCsv,
      toggleSearching,
      isSearching,
      approvalFilters,
      toggleApproveRewardsModal,
      dateRanges,
      clearDateRanges,
    };
  },
};
</script>
