<template>
  <div class="bg-primary-5 border-r-10 p-4 mt-4">
    <h1 class="text-primary-0 fs-4 mb-0 fw-bold mb-3">Lastes registrations</h1>

    <DataTable
      :value="registersInfo.lastRegisters.data"
      responsiveLayout="scroll"
      :loading="registersInfo.lastRegisters.isLoading"
      class="bg-white border-r-10 overflow-hidden"
    >
      <template #empty> No last transactions found. </template>
      <template #loading>
        Loading last transactions data. Please wait.
      </template>
      <column></column>
      <column
        v-for="(header, index) in registersInfo.lastRegisters.header"
        :key="index"
        :field="header.field"
        :header="header.name"
        style="min-width: 14rem"
      >
        <template #body="{ data }">
          <p>
            {{
              header.field === "date"
                ? formatDate(data["date"], "lastPays")
                : data[header.field]
            }}
          </p>
        </template>
      </column>
    </DataTable>
  </div>
</template>

<script>
import useRegisters from "@/modules/admin/composables/Analitycs/Marketing/useRegisters";
import formatDate from "@/shared/utils/changeTexts/formatDate";
export default {
  setup() {
    const { registersInfo } = useRegisters();
    return { registersInfo, formatDate };
  },
};
</script>