import { computed, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import { FilterMatchMode } from "primevue/api";
import filterDateRanges from "@/modules/admin/helpers/filterDateRanges";

const useRewardsApproved = () => {
  const store = useStore();
  const dateRanges = ref([]);

  const listApproved = computed(
    () => store.getters["admins/getMarketing"].rewards.approved
  );

  const approvedInfo = computed(() => {
    const datesRanges_ = dateRanges.value ?? [];
    const hasDateRanges = dateRanges.value.length > 0;

    const currentAproved = { ...listApproved.value };
    const data = listApproved.value.data;

    if (hasDateRanges)
      currentAproved.data = filterDateRanges({
        datesRanges: datesRanges_,
        data,
        fieldDate: "claimend",
      });

    return currentAproved;
  });

  const clearDateRanges = () => {
    dateRanges.value = [];
  };
  const getRewardsApproved = () =>
    store.dispatch("admins/marketingRewardsApproved");

  const approvedFilters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    last_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    claimend: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    discount: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    reward: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    user_payment_status: {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH,
    },
  });

  const isSearching = ref(false);
  const toggleSearching = () => {
    isSearching.value = !isSearching.value;
  };

  return {
    approvedInfo,
    getRewardsApproved,
    approvedFilters,
    isSearching,
    toggleSearching,
    dateRanges,
    clearDateRanges,
  };
};

export default useRewardsApproved;
