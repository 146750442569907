<template>
  <div class="bg-primary-5 border-r-10 p-4 mt-4" id="chart-registers">
    <div class="d-flex align-items-center justify-content-between">
      <h1 class="fs-3 text-primary-0 fw-bold mb-0">Registrations</h1>

      <select-button
        class="select-buttons-custom w-100"
        v-model="currentChart"
        :options="selectOptions"
      />
    </div>
    <hr class="line-blue" />
    <Chart
      type="bar"
      :data="currentChartData[currentChart]"
      :options="chartOptions"
      :plugins="[ChartJsPluginDataLabels, bgColor]"
    />
  </div>
</template>
<script>
import useRegisters from "@/modules/admin/composables/Analitycs/Marketing/useRegisters";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
import bgColor from "@/shared/utils/charts/bgColor";
export default {
  setup() {
    const {
      currentChartData,
      chartOptions,
      selectOptions,
      currentChart,
      registersInfo,
    } = useRegisters();
    return {
      currentChartData,
      chartOptions,
      selectOptions,
      ChartJsPluginDataLabels,
      bgColor,
      registersInfo,
      currentChart,
    };
  },
};
</script>
