import { computed, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
const useRegisters = () => {
  const store = useStore();

  const optionsCharts = ref([
    {
      label: "Marketing Registers Weekly",
    },
    {
      label: "Marketing Registers Monthly",
    },
    {
      label: "Marketing Registers Weekly and Monthly",
    },
    {
      label: "10 Lastest Transactions",
    },
  ]);

  const currentChartData = ref({
    Weekly: {
      datasets: [
        {
          type: "bar",
          label: "Weekly",
          data: [0, 0, 0, 0, 0],
          borderRadius: 20,
          backgroundColor: [
            "#74DAD9",
            "#FAB086",
            "#74DAD9",
            "#74DAD9",
            "#74DAD9",
            "#74DAD9",
            "#74DAD9",
          ],
        },
      ],
    },
    Monthly: {
      datasets: [
        {
          type: "bar",
          label: "Monthly",
          data: [0, 0, 0, 0, 0],
          borderRadius: 20,
          backgroundColor: [
            "#74DAD9",
            "#FAB086",
            "#74DAD9",
            "#74DAD9",
            "#74DAD9",
            "#74DAD9",
            "#74DAD9",
          ],
        },
      ],
    },
  });

  const chartOptions = ref({
    plugins: {
      legend: {
        labels: {
          color: "#495057",
        },
      },
      datalabels: {
        color: "white",
        textAlign: "center",
        font: {
          weight: "bold",
          size: 16,
        },
      },
    },
    scales: {
      x: {
        labels: ["Facebook", "Google", "Tiktok", "Friend Referral", "Other"],
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
      y: {
        min: 0,
        max: 20,
        ticks: {
          stepSize: 5,
        },
      },
    },
  });

  const selectOptions = ref(["Weekly", "Monthly"]);
  const currentChart = ref("Weekly");
  const getRegisters = () => store.dispatch("admins/marketingRegistersStart");
  const registersInfo = computed(() => store.getters["admins/getMarketing"]);

  const setInfoRegisters = (total_registers_week, total_lessons_month) => {
    Object.entries(total_registers_week).forEach(([key, value]) => {
      switch (key) {
        case "Facebook":
          currentChartData.value.Weekly.datasets[0].data[0] = value;
          currentChartData.value.Monthly.datasets[0].data[0] =
            total_lessons_month.Facebook;

          break;
        case "Friend_Referral":
          currentChartData.value.Weekly.datasets[0].data[1] = value;
          currentChartData.value.Monthly.datasets[0].data[1] =
            total_lessons_month.Friend_Referral;

          break;
        case "Google":
          currentChartData.value.Weekly.datasets[0].data[2] = value;
          currentChartData.value.Monthly.datasets[0].data[2] =
            total_lessons_month.Google;

          break;
        case "Other":
          currentChartData.value.Weekly.datasets[0].data[3] = value;
          currentChartData.value.Monthly.datasets[0].data[3] =
            total_lessons_month.Other;

          break;
        case "Tiktok":
          currentChartData.value.Monthly.datasets[0].data[4] = value;
          currentChartData.value.Monthly.datasets[0].data[4] =
            total_lessons_month.Tiktok;

          break;
      }
    });
  };

  const openToast = (message, status) => {
    const notification = { isOpen: true };
    notification.message = message;
    notification.status = status;
    store.commit("shared/uiNotificationStatus", notification);
    store.dispatch("shared/resetNotificationStatus");
  };

  const exportChart = () => {
    const doc = new jsPDF("p", "pt", "letter");
    const chart = document.querySelector("#chart-registers");
    const margin = 10;
    const scale =
      (doc.internal.pageSize.width - margin * 2) / chart.offsetWidth;
    doc.html(chart, {
      x: margin,
      y: margin,
      background: "#fff",
      html2canvas: { scale },
      callback: function (doc) {
        openToast(
          "The charts selected have been exported successfully.",
          "success"
        );
        doc.save("registers.pdf");
      },
    });
  };

  watchEffect(() => {
    let { graphic } = store.getters["admins/getMarketing"];
    let { data } = graphic;
    let { total_registers_week, total_registers_month } = data;
    if (
      total_registers_week !== undefined ||
      total_registers_month !== undefined
    )
      setInfoRegisters(total_registers_week, total_registers_month);
  });

  return {
    optionsCharts,
    currentChartData,
    chartOptions,
    selectOptions,
    currentChart,
    getRegisters,
    exportChart,
    registersInfo,
  };
};

export default useRegisters;
