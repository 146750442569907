<template>
  <div class="d-flex justify-content-between align-items-center">
    <div class="field field-blue field-blue-2 field-big border-0 border-r-10">
      <Dropdown
        v-model="selectView"
        :options="options"
        optionLabel="name"
        placeholder="Select an option"
        styleClass="fs-3"
      />
    </div>
    <button
      @click="exportChart"
      class="
        btn
        text-secondary-1
        border-secondary-1
        bg-hover-light-secondary-1
        py-2
        px-4
        d-flex
        align-items-center
        gap-2
      "
      v-if="selectView.name === 'Registrations'"
    >
      <span class="material-icons"> import_export </span>
      Export charts
    </button>
  </div>
  <marketing-rewards-view v-if="selectView.name === 'Rewards'" />
  <marketing-register-view v-if="selectView.name === 'Registrations'" />
</template>

<script>
import { ref, onUpdated, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import MarketingRewardsView from "@/modules/admin/views/Analytics/Marketing/MarketingRewardsView";
import MarketingRegisterView from "@/modules/admin/views/Analytics/Marketing/MarketingRegisterView";
import useRegisters from "@/modules/admin/composables/Analitycs/Marketing/useRegisters";
export default {
  components: {
    MarketingRewardsView,
    MarketingRegisterView,
  },
  setup() {
    const route = useRoute();
    const selectView = ref({name: route.params.view ?? "Rewards"});
    const { exportChart } = useRegisters();

    return {
      selectView,
      exportChart,
      options: [{ name: "Rewards" }, { name: "Registrations" }],
    };
  },
};
</script>