import { computed, ref } from "vue";
import { useStore } from "vuex";

const useRewards = () => {
  const store = useStore();
  const tableRewardsApproval = ref(null);
  const tableRewardsApproved = ref(null);
  const fieldsExcelRewardsApproval = ref({});
  const fieldsExcelRewardsApproved = ref({});
  const dataExcel = ref([]);
  const selectEmails = ref([]);
  const emailSelect = ref({});
  const loadingExport = ref(false);
  const loadingApproval = ref(false);
  const payload = ref({
    date_claimend: "",
    date_renewal: "",
    approve_reward: 2,
  });
  const exportChartModal = computed(
    () => store.getters["admins/analyticsMarketingModal"].exportCharts
  );
  const approveRewardsModal = computed(
    () => store.getters["admins/analyticsMarketingModal"].approveRewards
  );

  const currentTable = computed(
    () => store.getters["admins/getMarketing"].rewards.currentTable
  );

  const changeCurrentTable = (currentTable) => {
    store.commit("admins/setCurrentTableRewards", currentTable);
  };

  const classColors = {
    "secondary-0": ["drop"],
    "secondary-1": ["enrollment", "reference"],
    "accent-1": ["video"],
    "accent-2": ["trial", "lead"],
  };

  const validateText = (text = "") => {
    if (!text) return "";
    const newText = text.toLowerCase();
    for (let key in classColors) {
      if (classColors[key].includes(newText)) {
        let bg = "bg-" + key;
        if (key !== "accent-2") bg += " text-white";
        return bg;
      }
    }
  };

  const openToast = (message, status) => {
    const notification = { isOpen: true };
    notification.message = message;
    notification.status = status;
    store.commit("shared/uiNotificationStatus", notification);
    store.dispatch("shared/resetNotificationStatus");
  };

  const exportDataElement = (className, tableRef) => {
    let elementRef = document.querySelector("." + className);
    tableRef.value.selection.length === 0
      ? tableRef.value.processedData.length === 0
        ? (dataExcel.value = tableRef.value.value)
        : (dataExcel.value = tableRef.value.processedData)
      : (dataExcel.value = tableRef.value.selection);

    let id = setTimeout(() => {
      openToast("The table selected has been exported successfully", "success");
      elementRef.click();
      clearTimeout(id);
      loadingExport.value = false;
    }, 3000);
  };

  const toggleApproveRewardsModal = (isOpen, data = {}) => {
    if (isOpen) {
      emailSelect.value = data;
      store.commit("admins/setApproval", { dataSelect: data });
    } else {
      emailSelect.value = {};
      store.commit("admins/setApproval", { dataSelect: {} });
    }
    store.commit("admins/toggleApproveRewardsModal", isOpen);
  };

  const onSelectRow = () => {
    let emails = [];
    selectEmails.value.forEach((email) => {
      if (email.reward === "video") emails.push(email);
    });
    store.commit("admins/setApproval", { data: emails });
  };

  const onSelectAll = (e) => {
    let emails = [];
    selectEmails.value = e.data;
    selectEmails.value.forEach((email) => {
      emails.push(email);
    });
    store.commit("admins/setApproval", { data: emails });
  };

  const unSelectAll = () => {
    selectEmails.value = [];
    store.commit("admins/setApproval", { data: [] });
  };

  const approval = async () => {
    loadingApproval.value = true;
    let dataSelect = store.getters["admins/getApproval"].dataSelect;
    if (dataSelect.claimend) {
      payload.value.date_claimend = dataSelect.claimend
        ?.split("/")
        .reverse()
        .join("-");

      payload.value.date_claimend = payload.value.date_claimend.split("-");
      payload.value.date_claimend =
        payload.value.date_claimend[0] +
        "-" +
        payload.value.date_claimend[2] +
        "-" +
        payload.value.date_claimend[1];
    }
    if (dataSelect.renewal) {
      payload.value.date_renewal = dataSelect.renewal
        ?.split("/")
        .reverse()
        .join("-");

      payload.value.date_renewal = payload.value.date_renewal.split("-");
      payload.value.date_renewal =
        payload.value.date_renewal[0] +
        "-" +
        payload.value.date_renewal[2] +
        "-" +
        payload.value.date_renewal[1];
    }
    const { success } = await store.dispatch("admins/approveReward", {
      payload: payload.value,
      id: dataSelect.id,
      show: true,
    });

    if (success) {
      payload.value.approve_reward = 1;
      payload.value.date_claimend = "";
      payload.value.date_renewal = "";
      store.commit("admins/setApproval", { dataSelect: {} });
      store.commit("admins/toggleApproveRewardsModal", false);
    }
    store.dispatch("admins/marketingRewardsApproval");
    loadingApproval.value = false;
  };

  const multipleApproval = async () => {
    loadingApproval.value = true;
    let allPass = [];
    const notification = { isOpen: true };

    selectEmails.value.forEach(async (email) => {
      payload.value.approve_reward = 2;
      payload.value.date_claimend = email.claimend;
      payload.value.date_renewal = email.renewal;
      const { success } = await store.dispatch("admins/approveReward", {
        payload: payload.value,
        id: email.id,
        show: false,
      });
      if (success) {
        allPass.push({ ...email, status: true });
        payload.value.approve_reward = 1;
        payload.value.date_claimend = "";
        payload.value.date_renewal = "";
      } else allPass.push({ ...email, status: false });
    });
    let findFalse = allPass.filter((email) => email.status === false);
    if (findFalse.length === 0) {
      notification.status = "success";
      notification.message = "All Students Approved";
    } else {
      notification.status = "error";
      notification.message = "Some Students not Approved (";
      findFalse.forEach((email, index) => {
        notification.message += `${
          index === findFalse.length - 1 ? email.email : email.email + ","
        } , `;
      });
    }
    store.commit("shared/uiNotificationStatus", notification);
    selectEmails.value = [];
    await store.dispatch("admins/marketingRewardsApproval");
    store.commit("admins/setApproval", { data: [] });
    toggleApproveRewardsModal(false);
    loadingApproval.value = false;
  };

  const exportCsv = (type) => {
    loadingExport.value = true;
    switch (type) {
      case "approval":
        const approvalInfo = store.getters["admins/getRewardsApproval"];
        setHeader(approvalInfo.header, fieldsExcelRewardsApproval);
        exportDataElement("approval-rewards", tableRewardsApproval);
        break;
      case "approved":
        const approvedInfo = store.getters["admins/getRewardsApproved"];
        setHeader(approvedInfo.header, fieldsExcelRewardsApproved);
        exportDataElement("approved-rewards", tableRewardsApproved);
        break;
    }
  };

  const setHeader = (headers, fieldsExcel) => {
    headers.forEach((header) => {
      fieldsExcel.value[header.name] = header.field;
    });
  };

  return {
    exportChartModal,
    multipleApproval,
    onSelectAll,
    loadingApproval,
    onSelectRow,
    unSelectAll,
    tableRewardsApproval,
    tableRewardsApproved,
    approval,
    dataExcel,
    payload,
    fieldsExcelRewardsApproval,
    fieldsExcelRewardsApproved,
    exportCsv,
    loadingExport,
    approveRewardsModal,
    currentTable,
    changeCurrentTable,
    validateText,
    selectEmails,
    toggleApproveRewardsModal,
  };
};

export default useRewards;
