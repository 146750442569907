import { ref, watchEffect, computed } from "vue";
import { useStore } from "vuex";
import { v4 as uuidv4 } from "uuid";
import { useRouter, useRoute } from "vue-router";
import moment from "moment";
const useScheduledTeacher = () => {
  const router = useRouter();
  const route = useRoute();
  const store = useStore();
  const activeCopyYear = ref(false);
  const isLoadingAvailableTeachers = ref(false);
  const calendarScheduled = ref(null);
  const teacherSelect = ref(null);
  const isChangingTeacher = ref(false);
  const gotTimezone = ref(false);
  const weekDates = computed(() => {
    return store.getters["admins/schedule"].weekDates
  })
  const scheduledTeacherInfo = ref({
    id: 6,
    name: "Julieta Adriana",
    lastName: "Ramos Sandoval",
    hasClass: false,
  });
  const selectTimezone = ref("US/Eastern");

  const staticTimezones = computed(() => {
    return store.getters["admins/scheduled"].staticTimezones;
  });
  const isLoadingSchedule = computed(() => {
    return store.getters["admins/scheduled"].isLoadingSchedule;
  });
  const events = computed(() => {
    return store.getters["admins/scheduled"].events;
  });

  const newEvent = computed(() => {
    return store.getters["admins/scheduled"].newEvent;
  });

  const isLoadingInfo = computed(() => {
    return store.getters["admins/scheduled"].isLoading;
  });

  const isOpenDeleteEvent = computed(() => {
    return store.getters["admins/scheduled"].isOpenDeleteEvent;
  });

  const idDeleteEvent = computed(() => {
    return store.getters["admins/scheduled"].idDeleteEvent;
  });

  const isLoadingSave = computed(() => {
    return store.getters["admins/scheduled"].isLoadingSave;
  });

  const isOpenLastChanges = computed(() => {
    return store.getters["admins/scheduled"].isOpenLastChanges;
  });

  const isOpenListTeachers = computed(() => {
    return store.getters["admins/scheduled"].isOpenListTeachers;
  });

  const isOpenAcceptChange = computed(() => {
    return store.getters["admins/scheduled"].isOpenAcceptChange;
  });

  const dataClass = computed(() => {
    return store.getters["admins/scheduled"].dataClass;
  });

  const availableTeachers = computed(() => {
    return store.getters["admins/scheduled"].availableTeachers;
  });

  const toggleModalLastChanges = () => {
    const isOpenLastChanges =
      store.getters["admins/scheduled"].isOpenLastChanges;
    store.commit("admins/scheduled", { isOpenLastChanges: !isOpenLastChanges });
  };

  const toggleModalDeleteEvent = (id = null) => {
    const { isOpenDeleteEvent } = store.getters["admins/scheduled"];
    store.commit("admins/scheduled", {
      isOpenDeleteEvent: !isOpenDeleteEvent,
      idDeleteEvent: id,
    });
  };

  const clearDateFilter = () => {
    let { clear, events } = store.getters["admins/scheduled"];

    const clearStart = moment(clear.start).format("YYYY-MM-DD")
    const clearend = moment(clear.end).format("YYYY-MM-DD")

    events = events.filter((event) => {
      const startDate = moment(event.start).format("YYYY-MM-DD")
      const endDate = moment(event.end).format("YYYY-MM-DD")

      return !(moment(startDate).isSameOrBefore(clearend) &&
          moment(endDate).isSameOrAfter(clearStart)) 
          && event.type === "schedule"
    });
    store.commit("admins/scheduled", { events, isOpen: false });
  };

  const setTimeZones = (infoTeacher = {}) => {
    const teachertimezone = infoTeacher.time_zone ?? "America/Mexico_City";
    const { staticTimezones } = store.getters["admins/scheduled"];
    if(staticTimezones.length < 3)
    staticTimezones.push({
      label: "Teacher: " + teachertimezone,
      value: teachertimezone,
    });
    store.commit("admins/scheduled", { events , staticTimezones  });

  };

  const hasClassInWeek = (classes) => {
    const { weekDates } = store.getters["admins/scheduled"]
    let hasClass = false
    hasClass = classes.find( classe => {
      return moment(classe.start).isSameOrBefore(weekDates.end) &&
      moment(classe.end).isSameOrAfter(weekDates.start)
    })
    if(hasClass !== undefined)
    store.commit("admins/scheduled",{ hasClass : true })
    else
    store.commit("admins/scheduled",{ hasClass : false })

  }

  const getInfoTeacher = async (id, timezone) => {
    if(typeof timezone !== "string")
      timezone = timezone.value
    const { success, json } = await store.dispatch(
      "admins/workScheduleTeacher",
      { payload: { timezone }, id }
    );
    console.log(json)
    if (success) {
      store.commit("admins/scheduled", { events: [] });
      scheduledTeacherInfo.value.id = json.teacher.id;
      scheduledTeacherInfo.value.name = json.teacher.name;
      scheduledTeacherInfo.value.lastName = json.teacher.last_name;
      store.commit("admins/scheduled", { timezoneTeacher: json.teacher.time_zone })
      let events = store.getters["admins/scheduled"].events;
      setTimeZones(json.teacher);

      json.work_schedule.forEach((schedule) => {
        const event = createFormatEvent(schedule, "getting");
        events.push(event);
       
      });

      json.scheduled_classes_other.forEach((schedule) => {
        const event = createFormatEvent(schedule, "last");
        events.push(event);
       
      });

      hasClassInWeek(json.scheduled_classes)

      json.scheduled_classes.forEach((schedule) => {
        const event = createFormatEvent(schedule, "class");
        events.push(event);
      });
      store.commit("admins/scheduled", { events });
      store.commit("admins/scheduled", { isLoading: false });
    }
  };

  const loadServices = (timezone = null) => {
    getInfoTeacher(route.params.id, timezone ?  timezone : "US/Eastern");
  };

  const changeTimezone = ({ value }) => {
    store.commit("admins/scheduled", { isLoading: true });
    getInfoTeacher(route.params.id, value);
  };

  const getFormatEvents = (events) => {
    const newEvents = [];
    events.forEach((event) => {
      if (moment(event.end).diff(moment(event.start), "minutes") >= 30) {
        const newEvent = {
          time_start: moment(event.start).format("YYYY-MM-DD HH:mm:ss"),
          time_end: moment(event.end).format("YYYY-MM-DD HH:mm:ss"),
        };
        newEvents.push(newEvent);
      }
    });
    return newEvents;
  };

  const saveSchedule = async (eventsBefore) => {
    store.commit("admins/scheduled", { isLoadingSave: true , isLoadingSchedule :true });
    const { events } = store.getters["admins/scheduled"];
    const newEvents = getFormatEvents(events);
    const { success } = await store.dispatch("admins/saveWorkSchedule", {
      payload: { work_schedule: newEvents , timezone : "US/Eastern" },
      id: route.params.id,
    });
    if (success) {
      store.commit("admins/scheduled", { events: [] });
      gotTimezone.value = false;
      loadServices(selectTimezone.value);
    }else{
      store.commit("admins/scheduled", { events: eventsBefore });
    }
    store.commit("admins/scheduled", {
      isLoadingSave: false,
      isLoadingSchedule: false
    });
  };

  const createFormatEvent = (event, type = "automatic") => {
    event.deletable = type !== "class";
    event.resizable = false;
    event.draggable = false;
    event.background = true;
    event.idLesson = event.id
    event.class = type === "class" ? "yellow-event" : 
                  type === "last" ? "green-event" : "blue-event";
    event.type = type !== "class" ? "schedule" : "agended";
    event.title =
      type === "class"
        ? '<h6 style="font-size:15px">Scheduled class</h6>'
        : type === "last" ? ''
        : '<h6 style="font-size:15px">Job schedule</h6>'
    event.id = uuidv4();
    if (type === "manual") {
      event.start = event.start;
      event.end = event.end;
    } else if (type === "getting") {
      event.start = event.time_start.substr(0);
      event.end = event.time_end.substr(0);
    } else if (type === "class") {
      event.start = event.lesson_start.substr(0);
      event.end = event.lesson_end.substr(0);
    } else if (type === "last") {
      event.start = event.lesson_start.substr(0);
      event.end = event.lesson_end.substr(0);
    }

    return type === "automatic" || "getting" ? event : null;
  };

  const getFormatDate = (start, end) => {
    const startDay = moment(start).format('ddd/HH:mm:ss')
    const endDay = moment(end).format('ddd/HH:mm:ss')

    return startDay+'/'+endDay
  }

  const deleteEventData = ({ id }) => {
    let { events , weekDates , deleteEvents  }  = store.getters["admins/scheduled"];

    const deletedEvent = events.find(event => event.id === id);

    deleteEvents.push(deletedEvent)
    store.commit("admins/scheduled", { deleteEvents })

    events = events.filter((event) => {
      // if(typeof event.start === "object"){
      //   if((moment(event.start).isSameOrBefore(weekDates.end) &&
      //   moment(event.end).isSameOrAfter(weekDates.start))){
      //     deleteEvents.push(event)
      //     store.commit("admins/scheduled", { deleteEvents })
      //   }
      // }else{
      //   if((moment(new Date(event.start)).isSameOrBefore(weekDates.end) &&
      //   moment(new Date(event.end)).isSameOrAfter(weekDates.start))){
      //     deleteEvents.push(event)
      //     store.commit("admins/scheduled", { deleteEvents })

      //   }
      // }
      
      return event.id !== id;
    });

    console.log(deleteEvents)

    store.commit("admins/scheduled", {
      events,
      isOpenDeleteEvent: false,
      idDeleteEvent: null,
    });
  };

  const createEvent = (event) => {
    const newEvent = createFormatEvent(event);
    if (newEvent.start >= new Date(store.getters['shared/currentTimeTimezone']) && newEvent.start !== NaN) {
      const events = store.getters["admins/scheduled"].events;
      events.push(newEvent);
      store.commit("admins/scheduled", { isOpenCreate: false, events });
    }
    return newEvent.start >= new Date(store.getters['shared/currentTimeTimezone']) && newEvent.start !== NaN
      ? newEvent
      : false;
  };

  const createManualEvent = () => {
    const newEvent = store.getters["admins/scheduled"].newEvent;
    createFormatEvent(newEvent, "manual");

    const events = store.getters["admins/scheduled"].events;
    events.push(newEvent);
    store.commit("admins/scheduled", { isOpenCreate: false, events });
  };

  watchEffect(() => {
    const { timezone } = store.getters["admins/adminInfo"];
    if (timezone && !gotTimezone.value) {
      gotTimezone.value = true;
      let { staticTimezones } = store.getters["admins/scheduled"];
      staticTimezones.push({ label: "Admin:" + timezone, value: timezone });
      let hash = {};
      staticTimezones = staticTimezones.filter(timezone => hash[timezone.label] ? false : hash[timezone.label] = true)
      store.commit("admins/scheduled", { staticTimezones  });
    }
  });

  const scheduled = computed(() => {
    return store.getters["admins/scheduled"];
  });

  const toggleClearDateModal = () => {
    const { isOpen } = store.getters["admins/scheduled"];
    store.commit("admins/scheduled", { isOpen: !isOpen });
  };

  const toggleCreateJobscheduledModal = () => {
    const { isOpenCreate } = store.getters["admins/scheduled"];
    store.commit("admins/scheduled", { isOpenCreate: !isOpenCreate });
  };

  const goToPage = ({ id, name }) => {
    const route = { name };
    if (id) route.params = { id };
    router.push(route);
  };

  const deleteEventsAllYear = () => {
    let { deleteEvents , events  } = store.getters["admins/scheduled"]
    // let initialDays = 7;

    deleteEvents.map(event => {
      // const founds = []
      // for(let i = 0 ; i < 52 ; i++){
      //   const found = events.find(eventStore => {
      //     return typeof event.start === "object" 
      //     ? moment(moment(event.start).add(initialDays,'days')).format("YYYY-MM-DD HH:mm:ss") ===  typeof eventStore.start === "object" 
      //     ? moment(eventStore.start).format("YYYY-MM-DD HH:mm:ss") : eventStore.start
      //     : moment(moment(new Date(event.start)).add(initialDays,'days')).format("YYYY-MM-DD HH:mm:ss") ===  typeof eventStore.start === "object" ? moment(eventStore.start).format("YYYY-MM-DD HH:mm:ss") : eventStore.start
      //   })
      //   initialDays+=7
      //   if(found)
      //     founds.push(found)
      // }
      // founds.forEach(found => {
      //   events = events.filter(event => {
      //     return typeof (event.start === "object" ? moment(event.start).format("YYYY-MM-DD HH:mm:ss") === found.start : moment(new Date(event.start)).format("YYYY-MM-DD HH:mm:ss") === found.start )
      //   })
      // });

      const eventIndex = events.map(ev => ev.start+ev.end).indexOf(event.start+event.end)

      events.splice(eventIndex, 1)
      
    })

    store.commit("admins/scheduled",{ events , deleteEvents : [] })
  }

  const filterDeleteEvents = () => {
    let { deleteEvents , weekDates, events } = store.getters["admins/scheduled"]

    const eventsTodelete = deleteEvents.map(event =>{
      const formatTime = getFormatDate(event.start,event.end);

      return events.filter(evento => {
        if(getFormatDate(evento.start,evento.end) === formatTime) return evento
      })

    })[0]

    console.log(eventsTodelete)

    if(eventsTodelete){
      deleteEvents = [
        ...deleteEvents,
        ...eventsTodelete
      ]
    }

    // deleteEvents = deleteEvents.filter(event => {
    //   return typeof event.start === "object" 
    //   ? (moment(event.start).isSameOrBefore(weekDates.end) &&
    //       moment(event.end).isSameOrAfter(weekDates.start))
    //   : (moment(new Date(event.start)).isSameOrBefore(weekDates.end) &&
    //   moment(new Date(event.end)).isSameOrAfter(weekDates.start))
    // })

    store.commit("admins/scheduled",{ deleteEvents })

    if(deleteEvents.length > 0){
      deleteEventsAllYear()
    }

  }

  const copyOnYear = () => {
    const { hasClass , weekDates, events } = store.getters["admins/scheduled"];
    
    if (!hasClass) {
      store.commit("admins/scheduled", { isOpenLastChanges: false });
  
      const start = weekDates.start.format('YYYY-MM-DD')
      const end = weekDates.end.format('YYYY-MM-DD')

      const lastEvents = []
      
      const weekEvents = events.map(event => {
        let newEvent = {
          start: new Date(
            moment(event.start)
              .format("YYYY/MM/DD HH:mm:ss")
          ),
          end: new Date(
            moment(event.end)
              .format("YYYY/MM/DD HH:mm:ss")
          ),
        };
        newEvent = createFormatEvent(newEvent);
        const eventStart = moment(newEvent.start).format('YYYY-MM-DD')
        const eventEnd = moment(newEvent.end).format('YYYY-MM-DD')
        if(moment(eventStart).isSameOrBefore(end) && 
        moment(eventEnd).isSameOrAfter(start)){
          return newEvent
        }
        if(moment(eventStart).isBefore(start) &&
          moment(eventEnd).isBefore(start)){
            lastEvents.push(newEvent)
        }
      }).filter(event => event !== undefined)
  
      let initialDays = 7;
      const yearEvents = [
        ...weekEvents, 
        ...[...Array(51)].map(() => {
        const eventsCreated = weekEvents.map(event => {
          let newEvent = {
            start: new Date(
              moment(event.start)
                .add(initialDays, "days")
                .format("YYYY/MM/DD HH:mm:ss")
            ),
            end: new Date(
              moment(event.end)
                .add(initialDays, "days")
                .format("YYYY/MM/DD HH:mm:ss")
            ),
          };
          return createFormatEvent(newEvent);
        })
        initialDays += 7
        return eventsCreated
      }).flat()]
  
      store.commit("admins/scheduled", {
        events: [...lastEvents,...yearEvents],
        deleteEvents: []
      });
  
      saveSchedule(yearEvents);
      
    }else{
      const notification = { isOpen: true };
      notification.message = "You cannot copy the schedule with pending classes.";
      notification.status =  "error";
      store.commit("shared/uiNotificationStatus", notification);
      store.dispatch("shared/resetNotificationStatus");
      toggleModalLastChanges()
    }
  };

  const getEvents = ({ view, endDate, startDate }) => {
    console.log('getEvents')
    if (view !== "week") activeCopyYear.value = true;
    else {
      let { events , weekDates } = store.getters["admins/scheduled"]
      const classes = events.filter( classe => classe.type === "agended")
      weekDates.start = startDate;
      weekDates.end = endDate;
      store.commit("admins/scheduled",{weekDates})
      activeCopyYear.value = false;
      hasClassInWeek(classes)
    }
  };

  const toggleListTeachers = async (data=null) => {
    const { isOpenListTeachers } = store.getters["admins/scheduled"];
    
    if(data?.type === "agended"){
      console.log(1)
      isLoadingAvailableTeachers.value = true;
      const response = await store.dispatch("admins/availableTeachers", { payload : { 
        dates : [ { date: data.lesson_start}],
        timezone: selectTimezone.value
      }});
      const availableTeachers = response[ moment(data.lesson_start).format('YYYY-MM-DD HH:mm:ss')] 
      isLoadingAvailableTeachers.value = false;
      store.commit("admins/scheduled", { isOpenListTeachers: true, dataClass : data , availableTeachers });
      return
    }
    store.commit("admins/scheduled", { isOpenListTeachers: false });
  }

  const changeTeacher = async () => {
    isChangingTeacher.value = true;
    const { dataClass } = store.getters["admins/scheduled"];
    const { success } = await store.dispatch("admins/changeTeacher", { idLesson : dataClass.idLesson , idNewTeacher : teacherSelect.value });
    if(success){
      loadServices(selectTimezone);
    }
    toggleListTeachers()
    isChangingTeacher.value = false;
  }

  return {
    getEvents,
    isChangingTeacher,
    changeTeacher,
    scheduled,
    toggleListTeachers,
    toggleModalLastChanges,
    teacherSelect,
    isLoadingAvailableTeachers,
    availableTeachers,
    changeTimezone,
    isOpenDeleteEvent,
    activeCopyYear,
    saveSchedule,
    isOpenAcceptChange,
    isOpenListTeachers,
    deleteEventData,
    toggleClearDateModal,
    isOpenLastChanges,
    newEvent,
    isLoadingInfo,
    toggleModalDeleteEvent,
    copyOnYear,
    createEvent,
    toggleCreateJobscheduledModal,
    weekDates,
    scheduledTeacherInfo,
    goToPage,
    calendarScheduled,
    isLoadingSchedule,
    events,
    selectTimezone,
    staticTimezones,
    createManualEvent,
    clearDateFilter,
    loadServices,
    isLoadingSave,
    idDeleteEvent,
    dataClass,
  };
};

export default useScheduledTeacher;
